import { Card, Divider, Typography } from '@mui/material';

import { styled } from '#lib/mui/styled';

type WidgetCardProps = {
  title: string;
  fullHeight?: boolean;
  children: React.ReactNode;
  actionSlot?: React.ReactNode;
  subHeaderSlot?: React.ReactNode;
  'data-testid'?: string;
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})<{ fullHeight?: boolean }>(({ theme, fullHeight }) => ({
  borderRadius: theme.spacing(0.75),
  borderTop: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3, 2),
  minHeight: fullHeight ? '100%' : undefined,
  display: 'flex',
  flexDirection: 'column',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0, 3, 0),
}));

const StyledContents = styled('div')({
  flexGrow: 1,
});

const StyledHeaderContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr min-content',
  gap: '16px',
  wordWrap: 'break-word', // Ensures long words wrap to the next line
  overflowWrap: 'break-word', // Handles text breaking for all browsers
});

const StyledSubheaderSlot = styled('div')({
  gridColumn: '1 / -1',
});

/**
 * This card was created as a wrapper to emulate our dashboard widget appearance since we
 * won't be able to just copy/paste the existing dashboard widgets verbatim.
 */
export function WidgetCard(props: WidgetCardProps) {
  return (
    <StyledCard
      elevation={3}
      fullHeight={props.fullHeight}
      data-testid={props['data-testid']}
    >
      <StyledHeaderContainer>
        <Typography
          component="span"
          variant="h6"
          sx={{
            gridColumn: !props.actionSlot ? '1 / -1' : undefined,
          }}
        >
          {props.title}
        </Typography>

        {props.actionSlot && <div>{props.actionSlot}</div>}

        {props.subHeaderSlot && (
          <StyledSubheaderSlot>{props.subHeaderSlot}</StyledSubheaderSlot>
        )}
      </StyledHeaderContainer>

      <StyledDivider />

      <StyledContents>{props.children}</StyledContents>
    </StyledCard>
  );
}
