import type {
  ButtonProps,
  IconButtonProps,
  DialogContentProps as MuiDialogContentProps,
  DialogProps as MuiDialogProps,
  SxProps,
  TypographyProps,
} from '@mui/material';

import * as React from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import FocusLock from 'react-focus-lock';

import { useModal } from '#hooks';
import { grey } from '#lib/mui/colors';
import { styled } from '#lib/mui/styled';
import Confirmation from './Confirmation';
import Error from './Error';

const defaultCloseButtonSxProps = {};

const CloseButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function CloseButton(
    { 'aria-label': ariaLabel = 'Dismiss', ...props },
    forwardedRef
  ) {
    return (
      <IconButton
        ref={forwardedRef}
        aria-label={ariaLabel}
        size="small"
        {...props}
      >
        <Close fontSize="small" />
      </IconButton>
    );
  }
);

type CloseReason =
  | Parameters<NonNullable<MuiDialogProps['onClose']>>[1]
  | 'dismissButtonClick'
  | 'discardChanges'
  | 'error';

type CloseFn = (reason: CloseReason) => void;

type ModalContextValue = {
  onClose: CloseFn;
};

const ModalContext = React.createContext<ModalContextValue | null>(null);

const useModalContext = (): ModalContextValue =>
  React.useContext(ModalContext)!;

const LoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1,
}));

/**
 * Dialog
 */
export type RootProps = Omit<MuiDialogProps, 'onClose'> & {
  /**
   * Must have a stable reference.
   */
  onClose: CloseFn;
  closeButtonSxProps?: SxProps;
  /**
   * The only time where this should be `true` is when the modal is being used
   * for the confirmation modal because we want the user to take an action
   * explicitly.
   */
  disableCloseButton?: boolean;
  /**
   * Add a loader on top of the modal.
   */
  isLoading?: boolean;
  /**
   * Determine if the modal should "grow" and take all the available height,
   * similar to the `fullWidth` prop.
   */
  fullHeight?: boolean;
  /**
   * `requiresConfirmation` must have a stable reference if it is a function.
   */
  requiresConfirmation?: boolean | (() => boolean);
};

export const Root = ({
  open,
  closeButtonSxProps = defaultCloseButtonSxProps,
  scroll = 'paper',
  maxWidth = 'md',
  fullWidth = true,
  fullScreen = false,
  fullHeight = false,
  isLoading = false,
  requiresConfirmation = false,
  disableCloseButton = false,
  onClose,
  children,
  ...props
}: RootProps): JSX.Element => {
  const {
    dismiss: dismissConfirmationModal,
    open: openConfirmationModal,
    isOpen: isConfirmationModalOpen,
  } = useModal();

  const handleClose = React.useCallback(
    (reason: CloseReason) => {
      if (
        reason === 'error' ||
        reason === 'discardChanges' ||
        !(typeof requiresConfirmation === 'boolean'
          ? requiresConfirmation
          : requiresConfirmation())
      ) {
        onClose?.(reason);
        return;
      }

      openConfirmationModal();
    },
    [onClose, openConfirmationModal, requiresConfirmation]
  );

  React.useEffect(() => {
    if (open) return;
    dismissConfirmationModal();
  }, [dismissConfirmationModal, open]);

  const value = React.useMemo(() => ({ onClose: handleClose }), [handleClose]);

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Error error={error} onClose={() => handleClose('error')} />
      )}
    >
      <ModalContext.Provider value={value}>
        <MuiDialog
          open={open}
          scroll={scroll}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          PaperProps={{ sx: { height: fullHeight ? '100%' : undefined } }}
          onClose={(_event, reason) => handleClose(reason)}
          disableEnforceFocus={isLoading}
          {...props}
        >
          <FocusLock disabled={!isLoading}>
            {!disableCloseButton && (
              <CloseButton
                autoFocus
                disableFocusRipple
                sx={{
                  position: 'absolute',
                  backgroundColor: grey[300],
                  right: (theme) => theme.spacing(2),
                  top: (theme) => theme.spacing(2),
                  zIndex: 2,
                  '&:hover,&:focus': { backgroundColor: grey[400] },
                  ...closeButtonSxProps,
                }}
                onClick={() => handleClose('dismissButtonClick')}
              />
            )}
          </FocusLock>

          {isLoading && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}

          {isConfirmationModalOpen && (
            <Confirmation
              onGoBack={dismissConfirmationModal}
              onDiscard={() => handleClose('discardChanges')}
            />
          )}

          {children}
        </MuiDialog>
      </ModalContext.Provider>
    </ErrorBoundary>
  );
};

/**
 * Header
 */
export const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Title = styled(MuiDialogTitle)(() => ({
  padding: 0,
}));

const StyledDescription = styled(Typography)(() => ({
  color: grey[700],
}));

export const Description = ({
  variant = 'body1',
  ...props
}: TypographyProps): JSX.Element => (
  <StyledDescription variant={variant} {...props} />
);

/**
 * Content
 */
const StyledContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Content = ({
  dividers = true,
  ...props
}: MuiDialogContentProps): JSX.Element => (
  <StyledContent dividers={dividers} {...props} />
);

/**
 * Footer
 */
export const Footer = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  // Override mui's margin styles to handle spacing via gap instead.
  '& > *:not(:first-of-type)': {
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'stretch',
  },
}));

export function DismissButton({
  type = 'button',
  color = 'primary',
  variant = 'outlined',
  children = 'Dismiss',
  ...props
}: Omit<ButtonProps, 'onClick'>): JSX.Element {
  const { onClose } = useModalContext();

  return (
    <Button
      type={type}
      color={color}
      variant={variant}
      onClick={() => onClose('dismissButtonClick')}
      {...props}
    >
      {children}
    </Button>
  );
}
