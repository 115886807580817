import type { User } from '#services/api/user.ts';
import type { Appointment } from '#types/appointment.ts';

import { Typography } from '@mui/material';
import { format } from 'date-fns';

import * as AugmentedLink from '#components/AugmentedLink.tsx';
import { PatientStatuses } from '#components/PatientStatuses.tsx';
import { convertUtcStringToLocalDate } from '#utils/dates.ts';
import { getFullOrgLocationName } from '#utils/functions.ts';
import * as Fields from './Fields.tsx';

interface UserInformationProps {
  appointment: Appointment;
  user: User;
}

const DEFAULT_VALUE = '--';

export function UserInformation({ appointment, user }: UserInformationProps) {
  const guardian =
    user.role === 'Ward' && user.guardianUserIds?.[0] === appointment.patient.id
      ? appointment.patient
      : null;

  /**
   * Flag used to determine what fields to render for a user in a group visit,
   * when it is true, it is assumed the parent component displays them
   * somewhere else (those that apply).
   */
  const isGroupAppointment = Boolean(appointment.groupId);

  return (
    <Fields.Container>
      <Fields.Label>DOB</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {user.birthDate
            ? format(convertUtcStringToLocalDate(user.birthDate), 'MM/dd/yyyy')
            : DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      <Fields.Label>Patient Status</Fields.Label>
      <Fields.Value>
        {user.patientStatuses?.active &&
        Array.isArray(user.patientStatuses.active) ? (
          <PatientStatuses patientStatuses={user.patientStatuses.active} />
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>Gender</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">{user.gender ?? DEFAULT_VALUE}</Typography>
      </Fields.Value>

      {!isGroupAppointment && (
        <>
          <Fields.Provider provider={appointment.provider} />

          <Fields.Label>Reason for Visit</Fields.Label>
          <Fields.Value>
            <Typography variant="body2">
              {appointment.symptoms?.[0]?.content ?? DEFAULT_VALUE}
            </Typography>
          </Fields.Value>
        </>
      )}

      <Fields.Label>Contact Email</Fields.Label>
      <Fields.Value>
        {user.contact ? (
          <AugmentedLink.Root
            href={`mailto:${user.contact}`}
            tagId="userContactEmail"
          >
            <AugmentedLink.Label />
            <AugmentedLink.Copy value={user.contact} />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>Email</Fields.Label>
      <Fields.Value>
        {user.email ? (
          <AugmentedLink.Root href={`mailto:${user.email}`} tagId="userEmail">
            <AugmentedLink.Label />
            <AugmentedLink.Copy value={user.email} />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>Contact Phone</Fields.Label>
      <Fields.Value>
        {user.mobile ? (
          <AugmentedLink.Root
            href={`tel:${String(user.mobile).replace(/\s/g, '')}`}
            tagId="userContactPhone"
          >
            <AugmentedLink.Label />
            <AugmentedLink.Copy />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      {guardian && (
        <>
          <Fields.Label>Guardian Name</Fields.Label>
          <Fields.Value>
            <Typography variant="body2">
              {`${guardian.firstName} ${guardian.lastName}`}
            </Typography>
          </Fields.Value>

          <Fields.Label>Guardian Contact</Fields.Label>
          <Fields.Value>
            {guardian.mobile ? (
              <AugmentedLink.Root
                href={`tel:${String(guardian.mobile).replace(/\s/g, '')}`}
                tagId="guardianContactPhone"
              >
                <AugmentedLink.Label />
                <AugmentedLink.Copy />
              </AugmentedLink.Root>
            ) : (
              <Typography variant="body2">{DEFAULT_VALUE}</Typography>
            )}
          </Fields.Value>
        </>
      )}

      <Fields.Label>Address</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {user.addresses?.[0]
            ? getFullOrgLocationName(user.addresses[0])
            : DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      <Fields.Label>Chart ID</Fields.Label>
      <Fields.Value>
        {user.chartId ? (
          <AugmentedLink.Root href={user.chartId} tagId="chartId">
            <AugmentedLink.Label />
            <AugmentedLink.Copy />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>External EMR ID</Fields.Label>
      <Fields.Value>
        {user.externalEmrId ? (
          <AugmentedLink.Root href={user.externalEmrId} tagId="externalEmrId">
            <AugmentedLink.Label />
            <AugmentedLink.Copy />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>Connector ID</Fields.Label>
      <Fields.Value>
        {user.connectorId ? (
          <AugmentedLink.Root href={user.connectorId} tagId="connectorId">
            <AugmentedLink.Label />
            <AugmentedLink.Copy />
          </AugmentedLink.Root>
        ) : (
          <Typography variant="body2">{DEFAULT_VALUE}</Typography>
        )}
      </Fields.Value>

      <Fields.Label>Payment Type</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {appointment?.paidType ?? DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      <Fields.Label>Payment Description</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {appointment?.paidDescription ?? DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      {!isGroupAppointment && (
        <>
          <Fields.AppointmentID id={appointment.id} />

          <Fields.Label>Access Code</Fields.Label>
          <Fields.Value>
            {appointment.videoSession?.sessionCode ? (
              <AugmentedLink.Root
                href={appointment.videoSession.sessionCode}
                tagId="accessCode"
              >
                <AugmentedLink.Label />
                <AugmentedLink.Copy />
              </AugmentedLink.Root>
            ) : (
              <Typography variant="body2">{DEFAULT_VALUE}</Typography>
            )}
          </Fields.Value>
        </>
      )}

      <Fields.Label>Check-In Date</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {appointment.checkInDate?.[0]
            ? format(
                convertUtcStringToLocalDate(appointment.checkInDate),
                'MM/dd/yyyy'
              )
            : DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      <Fields.Label>Confirmed Date</Fields.Label>
      <Fields.Value>
        <Typography variant="body2">
          {appointment.confirmed?.[0]
            ? format(
                convertUtcStringToLocalDate(appointment.confirmed),
                'MM/dd/yyyy'
              )
            : DEFAULT_VALUE}
        </Typography>
      </Fields.Value>

      {!isGroupAppointment && (
        <>
          <Fields.Label>Patient Video Link</Fields.Label>
          <Fields.Value>
            {appointment.visitUri ? (
              <AugmentedLink.Root
                href={appointment.visitUri}
                tagId="patientVideoLink"
              >
                <AugmentedLink.Label asLink text={appointment.visitUri} />
                <AugmentedLink.Copy />
              </AugmentedLink.Root>
            ) : (
              <Typography variant="body2">{DEFAULT_VALUE}</Typography>
            )}
          </Fields.Value>

          <Fields.DirectVideoLink />
        </>
      )}
    </Fields.Container>
  );
}
